// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.student-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding: 10px;
}
.student-container:hover {
  background: #e7e7e7;
}
.student-container .student-details {
  display: flex;
  flex-direction: column;
}
.student-container .student-details h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}
.student-container .student-details p {
  margin-bottom: 0;
}
.student-container .progress-details {
  width: 80px;
  height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/progressdata.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;EACA,mBAAA;EACA,aAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;AAAJ;AAEI;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;AAAN;AAEI;EACE,gBAAA;AAAN;AAIE;EACE,WAAA;EACA,YAAA;AAFJ","sourcesContent":[".student-container {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n  align-items: center;\n  padding: 10px;\n\n  &:hover{\n    background: #e7e7e7;\n  }\n\n  .student-details {\n    display: flex;\n    flex-direction: column;\n\n    h2 {\n      font-size: 18px;\n      font-weight: 700;\n      margin-bottom: 0;\n    }\n    p{\n      margin-bottom: 0;\n    }\n  }\n\n  .progress-details{\n    width: 80px;\n    height: 80px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import "./allocate-content.scss";
import SearchBar from "../../components/searchbar/SearchBar";
import AllocationDetails from "../../components/allocate-content/AllocationDetails";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import SwipperPopUp from "../../components/allocate-content/swipper/SwipperPopUp";

const AllocateContent = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");
      try {
        const [analyticsResponse, emailResponse, courseResponse] =
          await Promise.all([
            axios.get(`${SERVER_URL}/user-analytics/user-courses-chapters`),
            axios.get(`${SERVER_URL}/admin/list/students`),
            axios.get(`${SERVER_URL}/course/courses-with-chapters`),
          ]);

        setData(analyticsResponse.data);
        setFilteredData(analyticsResponse.data);
        setEmails(emailResponse.data.map((item) => item.email));
        setCourseData(courseResponse.data);
      } catch (error) {
        setError("Error loading data!");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filterData = (searchTerm) => {
    const filtered = data.filter((item) =>
      item.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleSearch = (searchTerm) => {
    filterData(searchTerm);
  };

  const handleAddClick = () => {
    setSelectedEmail("");
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
    setSelectedEmail("");
  };

  const handleChaptersSelected = async (selectedChapters) => {
    try {
      setLoading(true);
      const filteredChapters = selectedChapters.filter(
        (course) => course.chapter.length > 0
      );
      await axios.post(`${SERVER_URL}/user-analytics/add`, {
        email: selectedEmail,
        course: filteredChapters,
      });
      const updatedData = await axios.get(
        `${SERVER_URL}/user-analytics/user-courses-chapters`
      );
      setData(updatedData.data);
      setFilteredData(updatedData.data);

      setLoading(false);
      setShowPopup(false);
      setSelectedEmail("");
    } catch (error) {
      console.error("Error adding user analytics:", error);
      setLoading(false);
    }
  };

  const handleChange = (name, value) => {
    setSelectedEmail(value);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/user-analytics/delete/${id}`
      );
      if (response.status === 200) {
        const updatedData = await axios.get(
          `${SERVER_URL}/user-analytics/user-courses-chapters`
        );
        setData(updatedData.data);
        setFilteredData(updatedData.data);
      }
    } catch (error) {
      console.error("Error deleting user analytics:", error);
    }
  };

  return (
    <div className="allocate-content-container">
      <div className="allocate-content-top">
        <div className="allocate-content-heading">
          <h1>
            <i className="bi-card-checklist"></i>Content Allocation
          </h1>
          <span>Content Allocation</span>
        </div>
        <div className="allocate-content-action">
          <SearchBar onSearch={handleSearch} />
          <div className="content-btn" onClick={handleAddClick}>
            <div className="icon">
              <i className="bi bi-plus-lg"></i>
            </div>
            <span>Allocate Content</span>
          </div>
        </div>
      </div>
      <div className="allocate-content-main">
        {loading && <p>Loading...</p>}
        {error && <p className="error">{error}</p>}
        {filteredData &&
          filteredData.map((item, index) => (
            <AllocationDetails
              key={index}
              data={item}
              onDelete={handleDelete}
            />
          ))}
      </div>

      {showPopup && (
        <div className="allocate-popup">
          <div className="allocate-popup-inner">
            <button className="content-close-button" onClick={handleClose}>
              &times;
            </button>
            <div className="allocate-popup-container">
              <h2>Allocate Content</h2>
              <form>
                <div className="allocate-form-group">
                  <label>Email:</label>
                  <select
                    name="email"
                    value={selectedEmail}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  >
                    <option value="">Select Email</option>
                    {emails.map((email, index) => (
                      <option key={index} value={email}>
                        {email}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="allocate-swipper">
                  <SwipperPopUp
                    courseData={courseData}
                    onChaptersSelected={handleChaptersSelected}
                    loading={loading}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllocateContent;

import React, { useState, forwardRef } from "react";
import "./examschedule.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PiPenNibStraight } from "react-icons/pi";
import { FaCalendarAlt } from "react-icons/fa";

const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => (
  <button className="custom-datePicker-input" onClick={onClick} ref={ref}>
    <FaCalendarAlt className="calendar-icon" />
  </button>
));

const ExamSchedule = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleChange = (date) => {
    setSelectedDate(date);
  };

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  return (
    <div className="scedule-wrapper">
      <h1>
        <PiPenNibStraight className="icon" />
        Exam Schedule
      </h1>
      <div className="calendar">
        <div className="schedule-info">
          <h1>{selectedDate ? selectedDate.getDate() : "27"}</h1>
          <p>
            {selectedDate ? formatDate(selectedDate) : "June, 2024"}
            <span> {selectedDate ? "Scheduled" : "Upcoming"}</span>
          </p>
        </div>
        <div className="date-picker">
          <DatePicker
            selected={selectedDate}
            onChange={handleChange}
            dateFormat="MM/dd/yyyy"
            showPopperArrow={false}
            customInput={<CustomDatePickerInput />}
          />
          <p>Schedule Exam</p>
        </div>
      </div>
    </div>
  );
};

export default ExamSchedule;

import React, { useEffect, useState } from "react";
import "./attendance.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { BiSelectMultiple } from "react-icons/bi";
import AttendancePieChart from "./charts/AttendancePieChart";

const Attendance = () => {
  const [pieChartData, setPieChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPieChartData = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(`${SERVER_URL}/admin/attendance`);
        setPieChartData(response.data);
      } catch (error) {
        setError("Error loading data!");
      } finally {
        setLoading(false);
      }
    };
    fetchPieChartData();
  }, []);

  return (
    <div className="attendance-wrapper">
      <h1>
        <BiSelectMultiple className="icon" />
        Today Attendance
      </h1>
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      {pieChartData.length > 0 ? (
        <AttendancePieChart data={pieChartData} />
      ) : (
        <p>No data is available!</p>
      )}
    </div>
  );
};

export default Attendance;

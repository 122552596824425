import React from "react";
import "./progressdata.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressData = ({ data }) => {
  return (
    <div className="student-container">
      <div className="student-details">
        <h2>{data.email}</h2>
        <p>Course: {data.course}</p>
        <span>Chapter:{data.chapter}</span>
      </div>
      <div className="progress-details">
        <CircularProgressbar
          strokeWidth={17.5}
          text={`${data.progress}`}
          value={data.progress}
          styles={buildStyles({
            textSize: "16px",
            pathColor: `#8c52ff`,
            textColor: "#012970",
            trailColor: "#545454",
            backgroundColor: "#3e98c7",
          })}
        />
      </div>
    </div>
  );
};

export default ProgressData;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attendance-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  border-radius: 10px;
  height: 100%;
}
.attendance-wrapper h1 {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
}
.attendance-wrapper h1 .icon {
  font-size: 26px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/attendance.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,4CAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,WAAA;AACJ;AACI;EACE,eAAA;AACN","sourcesContent":[".attendance-wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  background: #fff;\n  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n  padding: 20px;\n  border-radius: 10px;\n  height: 100%;\n\n  h1 {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n    font-size: 26px;\n    font-weight: 700;\n    text-transform: uppercase;\n    color: #000;\n\n    .icon {\n      font-size: 26px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import "./info.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import TotalClasses from "./info/TotalClasses";
import TotalStudents from "./info/TotalStudents";
import TotalAssignments from "./info/TotalAssignments";

const Info = () => {
  const [totalClass, setTotalClass] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalAssignments, setTotalAssignments] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");
      try {
        const [studentsResponse, chaptersResponse] = await Promise.all([
          axios.get(`${SERVER_URL}/admin/count/students`),
          axios.get(`${SERVER_URL}/user-analytics/count-total-chapters`),
        ]);
        const { totalStudents } = studentsResponse.data;
        const { totalChapters } = chaptersResponse.data;
        setTotalStudents(totalStudents);
        setTotalClass(totalChapters);
        setTotalAssignments(totalChapters);
      } catch (error) {
        setError("Error loading data!");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="info">
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      <TotalStudents totalStudents={totalStudents} />
      <TotalClasses totalClass={totalClass} />
      <TotalAssignments totalAssignments={totalAssignments} />
    </div>
  );
};

export default Info;

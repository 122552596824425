import React from "react";
import { ResponsiveLine } from "@nivo/line";

const processData = (data) => {
  if (!data || !Array.isArray(data)) {
    console.error("Data is missing or not in the expected format.");
    return [];
  }

  const groupedData = data.map((course) => ({
    id: course.course,
    data: course.data.map((item) => {
      const chapterName = Object.keys(item).find((key) => key !== "Percentage");
      return {
        x: chapterName,
        y: item.Percentage,
        date: item[chapterName],
      };
    }),
  }));

  return groupedData;
};

const CourseEffectivenessBumpChart = ({ data }) => {
  const chartData = processData(data);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveLine
        data={chartData}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: 0,
          max: 100,
          stacked: false,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickValues: "every 1",
          orient: "bottom",
          legend: "Chapter",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Assignment Percentage",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        colors={{ scheme: "nivo" }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        tooltip={({ point }) => (
          <div>
            <strong>{point.serieId}</strong>
            <br />
            Assignment Percentage: {point.data.y}%<br />
            Chapter: {point.data.x}
            <br />
            Date: {point.data.date}
          </div>
        )}
      />
    </div>
  );
};

export default CourseEffectivenessBumpChart;

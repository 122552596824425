import React from "react";
import "./taskdetails.scss";
import { MdOutlinePersonOutline } from "react-icons/md";

const TaskDetails = ({ data }) => {
  const getStatusDotColor = (status) => {
    if (status === "Completed" || status === "Available") {
      return "green";
    } else if (status === "In Progress") {
      return "orange";
    } else if (status === "Cancelled" || status === "Rejected") {
      return "red";
    } else {
      return "gray";
    }
  };

  const renderStatusDots = (status) => {
    const dotColor = getStatusDotColor(status);
    return (
      <span className="status-dot" style={{ backgroundColor: dotColor }}></span>
    );
  };

  return (
    <div className="task-details-container">
      <h4>
        <MdOutlinePersonOutline />
        {data.name}
      </h4>
      <p>- {data.designation}</p>
      <span>{data.job.substring(0, 100) + "..."}</span>
      <div className="status">
        <h5>
          Status:{renderStatusDots(data.status)}
          {data.status}
        </h5>
      </div>
      <hr />
    </div>
  );
};

export default TaskDetails;

import React, { useState } from "react";
import "./link.scss";
import { SERVER_URL } from "../../../utils/helper";
import axios from "axios";

const Link = ({ data, refreshData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [link, setLink] = useState("");

  const handleAddClick = () => {
    setModalMode("add");
    setLink("");
    setIsModalOpen(true);
  };

  const handleEditClick = () => {
    if (data.link) {
      setModalMode("edit");
      setLink(data.link);
      setIsModalOpen(true);
    }
  };

  const handleDeleteClick = () => {
    if (data.link) {
      setIsDeleteConfirmOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setLink("");
  };

  const handleCloseDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        link: link,
      };

      let response;
      if (modalMode === "add") {
        response = await axios.put(
          `${SERVER_URL}/course/${data._id}/link/add`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        response = await axios.put(
          `${SERVER_URL}/course/${data._id}/link/update`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

      if (response.status === 200 || response.status === 201) {
        handleCloseModal();
        refreshData();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteConfirm = async() => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/course/${data._id}/link/delete`
      );
      if (response.status === 200) {
        console.log("Delete confirmed");
        refreshData();
      }
      handleCloseDeleteConfirm();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <div className="intro">
        <h1>
          <i className="bi bi-link-45deg"></i> Link
        </h1>
      </div>
      <div className="content">
        <p>
          <b>Link:</b> {data.link ? data.link : "No link is available"}
        </p>
      </div>
      <div className="button-wrapper">
        <div
          className={`button ${data.link ? "disabled" : ""}`}
          onClick={data.link ? null : handleAddClick}
        >
          <div className="icon">
            <i className="bi bi-plus-lg"></i>
          </div>
          <span>Add Link</span>
        </div>
        <div className="button" onClick={handleEditClick}>
          <div className="icon">
            <i className="bi bi-pencil-square"></i>
          </div>
          <span>Edit Link</span>
        </div>
        <div className="button" onClick={handleDeleteClick}>
          <div className="icon">
            <i className="bi bi-trash"></i>
          </div>
          <span>Delete Link</span>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <h1>{modalMode === "add" ? "Add Link" : "Edit Link"}</h1>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label htmlFor="link">Link:</label>
                <input
                  type="text"
                  id="link"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  required
                />
              </div>
              <button type="submit">
                {modalMode === "add" ? "Add" : "Update"}
              </button>
            </form>
          </div>
        </div>
      )}

      {isDeleteConfirmOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseDeleteConfirm}>
              &times;
            </span>
            <h1>Confirm Delete</h1>
            <p>Are you sure you want to delete this link?</p>
            <div className="delete-buttons">
              <button onClick={handleDeleteConfirm}>Yes</button>
              <button onClick={handleCloseDeleteConfirm}>No</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Link;

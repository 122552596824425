import React from "react";
import "./tasks.scss";
import { GrTask } from "react-icons/gr";
import TaskDetails from "./TaskDetails";

const Tasks = () => {
  const task = [
    {
      name: "Sharath Kumar",
      designation: "Asst. Professor(Nursing)",
      job: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
      status: "In Progress",
    },
    {
      name: "Maheswari",
      designation: "Asst. Professor(Cyber Security)",
      job: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
      status: "Completed",
    },
    {
      name: "Madhesh",
      designation: "Asst. Professor(Soft Skills)",
      job: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
      status: "In Progress",
    },
  ];

  return (
    <div className="task-container">
      <h1>
        <GrTask />
        Task assigned
      </h1>
      <div className="stu-task">
        {task.map((item, index) => (
          <TaskDetails data={item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Tasks;

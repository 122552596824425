import React, { useState } from "react";
import "./requirements.scss";
import { SERVER_URL } from "../../../utils/helper";
import axios from "axios";

const Requirements = ({ data, refreshData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [requirements, setRequirements] = useState("");

  const handleAddClick = () => {
    setModalMode("add");
    setRequirements("");
    setIsModalOpen(true);
  };

  const handleEditClick = () => {
    if(data.requirements){
      setModalMode("edit");
      setRequirements(data.requirements !== "" ? data.requirements : "");
      setIsModalOpen(true);
    }
  };

  const handleDeleteClick = () => {
    if(data.requirements){
      setIsDeleteConfirmOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setRequirements("");
  };

  const handleCloseDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  const handleFormSubmit = async(e) => {
    e.preventDefault();
    try {
      const formData = {
        requirements: requirements,
      };

      let response;
      if (modalMode === "add") {
        response = await axios.put(
          `${SERVER_URL}/course/${data._id}/requirements/add`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        response = await axios.put(
          `${SERVER_URL}/course/${data._id}/requirements/update`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

      if (response.status === 200 || response.status === 201) {
        handleCloseModal();
        refreshData();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteConfirm = async() => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/course/${data._id}/requirements/delete`
      );
      if (response.status === 200) {
        refreshData();
      }
      handleCloseDeleteConfirm();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <div className="intro">
        <h1>
          <i className="bi bi-card-list"></i> Requirements
        </h1>
      </div>
      <div className="content">
        <p>
          <b>Requirements:</b>{" "}
          {data.requirements
            ? data.requirements
            : "No requirements info is available"}
        </p>
      </div>
      <div className="button-wrapper">
        <div
          className={`button ${data.requirements ? "disabled" : ""}`}
          onClick={handleAddClick}
        >
          <div className="icon">
            <i className="bi bi-plus-lg"></i>
          </div>
          <span>Add Requirements</span>
        </div>
        <div className="button" onClick={handleEditClick}>
          <div className="icon">
            <i className="bi bi-pencil-square"></i>
          </div>
          <span>Edit Requirements</span>
        </div>
        <div className="button" onClick={handleDeleteClick}>
          <div className="icon">
            <i className="bi bi-trash"></i>
          </div>
          <span>Delete Requirements</span>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <h1>
              {modalMode === "add" ? "Add Requirements" : "Edit Requirements"}
            </h1>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <textarea
                  id="requirements"
                  value={requirements}
                  onChange={(e) => setRequirements(e.target.value)}
                  required
                />
                <label htmlFor="requirements">Requirements:</label>
              </div>
              <button type="submit">
                {modalMode === "add" ? "Add" : "Update"}
              </button>
            </form>
          </div>
        </div>
      )}

      {isDeleteConfirmOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseDeleteConfirm}>
              &times;
            </span>
            <h1>Confirm Delete</h1>
            <p>Are you sure you want to delete these requirements?</p>
            <div className="delete-buttons">
              <button onClick={handleDeleteConfirm}>Yes</button>
              <button onClick={handleCloseDeleteConfirm}>No</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Requirements;

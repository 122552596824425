import React from "react";
import "./chats.scss";
import { BsChatRightDots } from "react-icons/bs";
import ChatUser from "./ChatUser";

const Chats = () => {
  const staffs = [
    {
      name: "Akshitha",
      designation: "Professor (Nursing)",
      status: "Available",
    },
    {
      name: "Vignesh",
      designation: "Professor (Cyber Security)",
      status: "Available",
    },
    {
      name: "Anugraha",
      designation: "Professor (Soft Skills)",
      status: "Available",
    },
  ];

  return (
    <div className="chat-container">
      <h1>
        <BsChatRightDots />
        Chat
      </h1>
      <div className="stu-chats">
        {staffs.map((item, index) => (
          <ChatUser data={item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Chats;

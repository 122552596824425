import React from "react";
import "./dashboard.scss";
import Reports from "../../components/dashboard/Reports";
import ExamSchedule from "../../components/dashboard/ExamSchedule";
import Attendance from "../../components/dashboard/Attendance";
import Info from "../../components/dashboard/Info";
import TopPerformingCourses from "../../components/dashboard/TopPerformingCourses";
import StudentProgress from "../../components/dashboard/StudentProgress";
import Tasks from "../../components/dashboard/Tasks";
import CourseEffectivenessAnalysis from "../../components/dashboard/CourseEffectivenessAnalysis";
import LearningResourceEffectiveness from "../../components/dashboard/LearningResourceEffectiveness";
import Chats from "../../components/dashboard/Chats";

const Dashboard = () => {
  const passPercentage = 60;
  return (
    <div className="dashboard-container">
      <div className="top">
        <div className="top-left">
          <div className="reports-container">
            <Reports passPercentage={passPercentage} />
          </div>
          <div className="schedule-container">
            <ExamSchedule />
          </div>
        </div>
        <div className="top-middle">
          <Attendance />
        </div>
        <div className="top-right">
          <Info />
        </div>
      </div>
      <div className="middle">
        <div className="chart">
          <TopPerformingCourses />
        </div>
        <div className="progress-bars">
          <StudentProgress />
        </div>
        <div className="task">
          <Tasks />
        </div>
      </div>
      <div className="bottom">
        <div className="line-chart">
          <CourseEffectivenessAnalysis />
        </div>
        <div className="pie-chart">
          <LearningResourceEffectiveness />
        </div>
        <div className="chat">
          <Chats />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

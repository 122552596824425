// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.learning-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
}
.learning-container h1 {
  display: flex;
  gap: 7px;
  align-items: center;
  font-size: 19px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/learningresourceeffectiveness.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,4CAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,WAAA;AACJ","sourcesContent":[".learning-container {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  background: #fff;\n  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n  height: 100%;\n  padding: 20px;\n  border-radius: 10px;\n\n  h1 {\n    display: flex;\n    gap: 7px;\n    align-items: center;\n    font-size: 19px;\n    font-weight: 700;\n    text-transform: uppercase;\n    color: #000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

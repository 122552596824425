import React, { useEffect, useState } from "react";
import "./topperformingcourses.scss";
import TopPerformingCoursesBarChart from "./charts/TopPerformingCoursesBarChart";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { IoBarChartSharp } from "react-icons/io5";

const TopPerformingCourses = () => {
  const [barChartData, setBarChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchBarChartData = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/count-emails`
        );
        setBarChartData(response.data);
      } catch (error) {
        setError("Error loading data!");
      } finally {
        setLoading(false);
      }
    };
    fetchBarChartData();
  }, []);

  return (
    <div className="barchart-container">
      <h1>
        <IoBarChartSharp />
        Top Performing Courses
      </h1>
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      {barChartData.length > 0 ? (
        <TopPerformingCoursesBarChart data={barChartData} />
      ) : (
        <p>No data is available!</p>
      )}
    </div>
  );
};

export default TopPerformingCourses;

import React, { useState } from "react";
import "./learningpoints.scss";
import { SERVER_URL } from "../../../utils/helper";
import axios from "axios";
import LearningPointsDetails from "./LearningPointsDetails";

const LearningPoints = ({ data, refreshData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const handleAddNews = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setName("");
    setImageFile(null);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("image", imageFile);

      const response = await axios.put(
        `${SERVER_URL}/course/${data._id}/learning-points/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        handleCloseModal();
        refreshData();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  return (
    <div className="learning-points-details">
      <div className="intro">
        <h1>
          <i className="bi bi-newspaper"></i> Learning Points
        </h1>
        <div className="button" onClick={handleAddNews}>
          <div className="icon">
            <i className="bi bi-plus-lg"></i>
          </div>
          <span>Add Learning Points</span>
        </div>
      </div>
      <div className="learning-points-info">
        {data.learningPoints &&
          data.learningPoints.map((lp, index) => (
            <LearningPointsDetails
              key={index}
              courseId={data._id}
              learningPoints={lp}
              refreshData={refreshData}
            />
          ))}
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <h1>Add Learning Points</h1>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="image">Thumbnail:</label>
                <input
                  type="file"
                  id="image"
                  onChange={handleFileChange}
                  accept="image/*"
                  required
                />
              </div>
              <button type="submit">Add</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default LearningPoints;

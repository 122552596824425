// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.total-students {
  display: flex;
  gap: 20px;
  padding: 20px;
  background: #545454;
  color: #fff;
  border-radius: 100px;
  align-items: center;
  flex: 1 1;
}
.total-students .left {
  display: flex;
  flex-direction: column;
  border-right: 2px solid;
  border-image: linear-gradient(#642bff, #ff22e6) 1;
  align-items: center;
  padding: 0px 20px;
}
.total-students .left h1 {
  font-size: 36px;
  font-weight: 700;
}
.total-students .left .icon {
  font-size: 40px;
}
.total-students .right {
  display: flex;
  flex-direction: column;
}
.total-students .right h1 {
  text-transform: uppercase;
  font-size: 26px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/info/totalstudents.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,oBAAA;EACA,mBAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,iDAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AACI;EACE,eAAA;EACA,gBAAA;AACN;AAEI;EACE,eAAA;AAAN;AAIE;EACE,aAAA;EACA,sBAAA;AAFJ;AAII;EACE,yBAAA;EACA,eAAA;AAFN","sourcesContent":[".total-students {\n  display: flex;\n  gap: 20px;\n  padding: 20px;\n  background: #545454;\n  color: #fff;\n  border-radius: 100px;\n  align-items: center;\n  flex: 1;\n\n  .left {\n    display: flex;\n    flex-direction: column;\n    border-right: 2px solid;\n    border-image: linear-gradient(#642bff, #ff22e6) 1;\n    align-items: center;\n    padding: 0px 20px;\n\n    h1 {\n      font-size: 36px;\n      font-weight: 700;\n    }\n\n    .icon {\n      font-size: 40px;\n    }\n  }\n\n  .right {\n    display: flex;\n    flex-direction: column;\n\n    h1 {\n      text-transform: uppercase;\n      font-size: 26px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

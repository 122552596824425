// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-user-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
}
.chat-user-container:hover {
  background: #e7e7e7;
  cursor: pointer;
}
.chat-user-container h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  display: flex;
  gap: 5px;
  align-items: center;
}
.chat-user-container h4 .status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/chatuser.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AACF;AACE;EACE,mBAAA;EACA,eAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;AAAJ;AAEI;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AAAN","sourcesContent":[".chat-user-container {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n  padding: 10px;\n\n  &:hover {\n    background: #e7e7e7;\n    cursor: pointer;\n  }\n\n  h4 {\n    font-size: 18px;\n    font-weight: 700;\n    margin-bottom: 0;\n    display: flex;\n    gap: 5px;\n    align-items: center;\n\n    .status-dot {\n      display: inline-block;\n      width: 10px;\n      height: 10px;\n      border-radius: 50%;\n      margin-right: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

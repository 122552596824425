// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-details-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.task-details-container h4 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}
.task-details-container p {
  margin-bottom: 0;
}
.task-details-container span {
  color: #bdbdbd;
}
.task-details-container .status {
  display: flex;
  align-items: center;
  gap: 10px;
}
.task-details-container .status h5 {
  font-size: 16px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.task-details-container .status .status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/taskdetails.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,cAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAFJ;AAII;EACE,eAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;AAFN;AAKI;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AAHN","sourcesContent":[".task-details-container {\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n\n  h4 {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    font-size: 16px;\n    font-weight: 700;\n    margin-bottom: 0;\n  }\n\n  p {\n    margin-bottom: 0;\n  }\n\n  span {\n    color: #bdbdbd;\n  }\n\n  .status {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n\n    h5 {\n      font-size: 16px;\n      display: flex;\n      gap: 10px;\n      align-items: center;\n    }\n\n    .status-dot {\n      display: inline-block;\n      width: 10px;\n      height: 10px;\n      border-radius: 50%;\n      margin-right: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import "./owner.scss";
import { SERVER_URL } from "../../../utils/helper";
import axios from "axios";

const Owner = ({ data, refreshData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [owner, setOwner] = useState("");

  const handleAddClick = () => {
    setModalMode("add");
    setOwner("");
    setIsModalOpen(true);
  };

  const handleEditClick = () => {
    if (data.owner) {
      setModalMode("edit");
      setOwner(data.owner !== "" ? data.owner : "");
      setIsModalOpen(true);
    }
  };

  const handleDeleteClick = () => {
    if (data.owner) {
      setIsDeleteConfirmOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setOwner("");
  };

  const handleCloseDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        owner: owner,
      };

      let response;
      if (modalMode === "add") {
        response = await axios.put(
          `${SERVER_URL}/course/${data._id}/owner/add`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        response = await axios.put(
          `${SERVER_URL}/course/${data._id}/owner/update`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

      if (response.status === 200 || response.status === 201) {
        handleCloseModal();
        refreshData();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/course/${data._id}/owner/delete`
      );
      if (response.status === 200) {
        refreshData();
      }
      handleCloseDeleteConfirm();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <div className="intro">
        <h1>
          <i className="bi bi-person-square"></i> Owner
        </h1>
      </div>
      <div className="content">
        <p>
          <b>Owner:</b> {data.owner ? data.owner : "No owner info is available"}
        </p>
      </div>
      <div className="button-wrapper">
        <div
          className={`button ${data.owner ? "disabled" : ""}`}
          onClick={data.owner ? null : handleAddClick}
        >
          <div className="icon">
            <i className="bi bi-plus-lg"></i>
          </div>
          <span>Add Owner</span>
        </div>
        <div className="button" onClick={handleEditClick}>
          <div className="icon">
            <i className="bi bi-pencil-square"></i>
          </div>
          <span>Edit Owner</span>
        </div>
        <div className="button" onClick={handleDeleteClick}>
          <div className="icon">
            <i className="bi bi-trash"></i>
          </div>
          <span>Delete Owner</span>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <h1>{modalMode === "add" ? "Add Owner" : "Edit Owner"}</h1>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label htmlFor="owner">Owner:</label>
                <input
                  type="text"
                  id="owner"
                  value={owner}
                  onChange={(e) => setOwner(e.target.value)}
                  required
                />
              </div>
              <button type="submit">
                {modalMode === "add" ? "Add" : "Update"}
              </button>
            </form>
          </div>
        </div>
      )}

      {isDeleteConfirmOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseDeleteConfirm}>
              &times;
            </span>
            <h1>Confirm Delete</h1>
            <p>Are you sure you want to delete this owner?</p>
            <div className="delete-buttons">
              <button onClick={handleDeleteConfirm}>Yes</button>
              <button onClick={handleCloseDeleteConfirm}>No</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Owner;

import React from "react";
import "./reports.scss";
import { TbReportSearch } from "react-icons/tb";
import { FaRankingStar } from "react-icons/fa6";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Reports = ({ passPercentage }) => {
  return (
    <div className="reports-wrapper">
      <div className="reports-top">
        <div className="reports-heading">
          <h1>
            <TbReportSearch /> Reports
            <span>Recent Reports</span>
          </h1>
        </div>
        <p>
          <i className="bi bi-receipt-cutoff icon"></i>
          Quarterly Exam Reports
        </p>
        <div className="progress-info">
          <div className="progress-container">
            <CircularProgressbar
              strokeWidth={17.5}
              text={`${passPercentage}%`}
              value={passPercentage}
              styles={buildStyles({
                textSize: "16px",
                pathColor: `#8c52ff`,
                textColor: "#012970",
                trailColor: "#545454",
                backgroundColor: "#3e98c7",
              })}
            />
          </div>
          <hr className="divider" />
          <p>Pass percentage is low</p>
        </div>
      </div>
      <div className="reports-bottom">
        <p>
          <FaRankingStar className="icon" />
          Top Ranks
          <br />
          <span>34 students got top rank in cyber security</span>
        </p>
      </div>
    </div>
  );
};

export default Reports;

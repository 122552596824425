import React, { useEffect, useState } from "react";
import "./learningresourceeffectiveness.scss";
import { BiScatterChart } from "react-icons/bi";
import LearningResourceScatterChart from "./charts/LearningResourceScatterChart";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const LearningResourceEffectiveness = () => {
  const [scatterChartData, setScatterChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchScatterPlotData = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/assignments-completed`
        );
        if (response.data && Array.isArray(response.data)) {
          const transformedData = response.data.map((course) => ({
            courseName: course.course,
            data: course.data.map((item) => {
              const chapterName = Object.keys(item).find(
                (key) => key !== "Percentage"
              );
              return {
                chapterName,
                assignmentPercentage: item.Percentage,
                date: item[chapterName],
              };
            }),
          }));
          setScatterChartData(transformedData);
        } else {
          setScatterChartData([]);
        }
      } catch (error) {
        console.error("Error loading data:", error);
        setError("Error loading data!");
      } finally {
        setLoading(false);
      }
    };
    fetchScatterPlotData();
  }, []);

  return (
    <div className="learning-container">
      <h1>
        <BiScatterChart />
        Learning Resource Effectiveness
      </h1>
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      {scatterChartData.length > 0 ? (
        <LearningResourceScatterChart data={scatterChartData} />
      ) : (
        <p>No data available!</p>
      )}
    </div>
  );
};

export default LearningResourceEffectiveness;

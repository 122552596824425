import React from "react";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";

const LearningResourceScatterChart = ({ data }) => {
  if (!Array.isArray(data)) {
    return <div>No valid data available</div>;
  }

  const convertData = (data) => {
    return data.map((course) => ({
      id: course.courseName,
      data: course.data.map((item) => ({
        x: item.chapterName,
        y: item.assignmentPercentage,
        date: item.date, // Include the date for tooltip
      })),
    }));
  };

  const convertedData = convertData(data);

  return (
    <div style={{ height: "400px" }}>
      <ResponsiveScatterPlot
        data={convertedData}
        xScale={{ type: "point" }}
        yScale={{ type: "linear", min: 0, max: 100 }}
        margin={{ top: 50, right: 60, bottom: 70, left: 80 }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          legend: "Chapter Name",
          legendPosition: "middle",
          legendOffset: 46,
        }}
        axisLeft={{
          orient: "left",
          legend: "Assignment Percentage",
          legendPosition: "middle",
          legendOffset: -60,
        }}
        tooltip={({ node }) => (
          <div>
            <strong>{node.serieId}</strong>
            <br />
            Assignment Percentage: {node.data.y}%<br />
            Chapter Name: {node.data.x}
            <br />
            Date: {node.data.date}
          </div>
        )}
      />
    </div>
  );
};

export default LearningResourceScatterChart;

import React from "react";
import "./chatuser.scss";
import { IoPersonOutline } from "react-icons/io5";

const ChatUser = ({ data }) => {
  const getStatusDotColor = (status) => {
    if (status === "Completed" || status === "Available") {
      return "green";
    } else if (status === "In Progress") {
      return "orange";
    } else if (status === "Cancelled" || status === "Rejected") {
      return "red";
    } else {
      return "gray";
    }
  };

  const renderStatusDots = (status) => {
    const dotColor = getStatusDotColor(status);
    return (
      <span className="status-dot" style={{ backgroundColor: dotColor }}></span>
    );
  };

  return (
    <div className="chat-user-container">
      <h4>
        <IoPersonOutline />
        {data.name} - {renderStatusDots(data.status)}
      </h4>
      <span>{data.designation}</span>
    </div>
  );
};

export default ChatUser;
